<template>
  <div>
    <div class="comment">
      <div class="titleBox">
        <p>{{ '课程评价' }}</p>
      </div>
      <div class="contentBox" v-loading="com_loading">
        <div class="inputBox">
          <!-- <img :src="avatar ? avatar : DEFAULT_AVATAR" alt="" /> -->
          <el-input
            class="inputSty"
            placeholder="写下您对该课程的留言"
            size="small"
            v-model="input"
            maxlength="200"
            @keyup.enter.native="handleSend"
            clearable
          >
          </el-input>
          <div style="text-align: right;padding-top: 20px;">
            <span class="len_num">{{input.length}}/200</span>
            <el-button type="primary" size="small" style='width:94px' @click="handleSend">{{
              $t("published")
            }}</el-button>
          </div>
        </div>
        <div class="totalBox">
          {{ $t("total") }}{{ total }}{{ $t("comments") }}
        </div>
        <div class="listBox">
          <commentItem
            v-for="(item, i) in comment_list"
            :key="i"
            :item="item"
            @del="del_child"
          ></commentItem>
          <noDataImg v-if="comment_list.length == 0 && !com_loading" />
          <div class="paginationBlock" v-if="total != 0">
            <el-pagination
              @current-change="handleChange"
              :total="total"
              :hide-on-single-page="true"
              :page-size="5"
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commentItem from "./commentItem";
export default {
  components: {
    commentItem,
  },
  data() {
    return {
      video_id:"",
      company_id:"",
      avatar: "",
      com_loading: false,
      input: "",
      total: 0,
      start: 1,
      comment_list: [],
    };
  },
  mounted() {
    this.video_id=this._decode(this.$route.query.parameter).video_id
    this.getList();
  },
  methods: {
    async getList() {
      this.com_loading = true;
      let params = {
         real_source:this.PJSource,
        source:6,
        related_id:this._decode(this.$route.query.parameter).video_id,
        start: this.start - 1,
        audit_status:1,
        limit: 5,
        nopage:0
      };
      let result = await this.$store.dispatch(
        "API_position/get_video_comment",
        params
      );
      if (result && result.success) {
        this.comment_list =result.data;
        this.total = result.count;
      }
      this.com_loading = false;
    },
    handleChange(e) {
      this.start = e;
      this.getList();
    },
    async handleSend() {
      if(!this.USER_INFO.id){
        this.$router.push("/sign");
        return
      }
      if (this.input == "") {
        this.$message.warning(this.$t("entercontent"));
        return;
      }
      this.com_loading = true;
      let params = {
        real_source:this.PJSource,//注册来源
        source:6,//来源：1-询盘，2-1v1，3-游客，4-视频，5-展台，6-课程
        related_id:this.video_id,//根据来源填写向对应的询盘ID、1v1ID、视频ID、展台ID
        content:this.input,//评论内容
        user_id:this.USER_INFO.id,//评论用户ID
        company_id:this.USER_INFO.company_id,//评论公司ID
        audit_status:0,//审核状态，0-待审核，1-审核通过，2-审核拒绝
      };
      console.log('params');
      let save = await this.$store.dispatch(
        "API_position/company_comment_save",
        params
      );
      if (save && save.success) {
        this.input = "";
        this.com_loading=false
        this.$message.info('评论审核通过后才能显示')
      }
    },
    del_child(e) {
      if(this.comment_list.length==1&&this.start>1){
        this.start=this.start-1
        this.getList();
      }else{
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang="less">
.comment {
  width: 804px;
  background: #fff;
  margin-bottom: 12px;
  padding: 0 20px;
  .titleBox {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .contentBox {
    .inputBox {
      margin-top: 16px;
      height: 118px;
      box-sizing: border-box;
      background: #F6F6F6;
      border-radius: 2px;
      padding: 16px;
      img {
        width: 36px;
        height: 36px;
      }
      .inputSty {
        margin: 0 12px 0 0;
      }
      .len_num{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        display:inline-block;
        padding-right: 17px;
      }
    }
    .totalBox {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top:16px;
    }
    .listBox {
      position: relative;
      min-height: 300px;
      .paginationBlock {
        text-align: right;
        margin-top: 15px;
        padding-bottom: 20px;
      }
    }
  }
}
</style>