<template>
  <div class="detail">
    <columnDetail
      v-if="item.ctype == 1"
      :info="item"
      :danmukuList="danmukuList"
      @buyChange="buyChanges"
    ></columnDetail>
    <videoDetail
      v-else
      :info="item"
      @buyChange="buyChanges"
      :danmukuList="danmukuList"
    ></videoDetail>
    <payDialog
      ref="payDialog"
      @paySuccessChange="paySuccess"
      @closeChange="getList"
    ></payDialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="300px">
      <div>
        <div class="openTitle">
          {{ dialogValue }}
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false">否</el-button>
        <el-button
          type="primary"
          size="small"
          @click="$router.push('/console/order/myOrder')"
          >是</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import columnDetail from "~scb/views/college/components/columnDetail";
import videoDetail from "~scb/views/college/components/videoDetail";
import payDialog from "~scb/components/common/payDialog";
import { priorFormat } from "@/basePlugins/filters";

export default {
  components: {
    columnDetail,
    videoDetail,
    payDialog,
  },
  metaInfo: {
    title: "课程详情",
  },
  data() {
    return {
      video_id: "",
      item: {},
      isSoucang: false,
      dialogVisible: false,
      dialogValue: "检测到有未支付的课程！是否去支付",
      danmukuList: [],
    };
  },
  mounted() {
    this.getList();
    this.getDanmukuList();
  },
  methods: {
    async getDanmukuList() {
      this.danmukuList = [];
      let params = {
        real_source: this.PJSource,
        source: 6,
        related_id: this._decode(this.$route.query.parameter).video_id,
        nopage: 1,
        audit_status: 1,
      };
      let result = await this.$store.dispatch(
        "API_position/get_video_comment",
        params
      );
      result.data.forEach((item) => {
        this.danmukuList.push(item.content);
      });
      this.danmukuList = this.danmukuList.reverse();
      console.log(this.danmukuList);
    },
    async getList() {
      try {
        let obj = this._decode(this.$route.query.parameter);
        let params = {
          course_id: obj.video_id,
          user_id: this.USER_INFO.id,
        };
        let res = await this.$store.dispatch(
          "API_company/getVideoTypeDetail",
          params
        );
        if (res.success) {
          this.$nextTick((e) => {
            this.item = res.data;
            this.item.price_actual = this.item.price_actual
              ? (this.item.price_actual / 100).toFixed(2)
              : 0;
            this.item.price_scribe = this.item.price_scribe
              ? (this.item.price_scribe / 100).toFixed(2)
              : 0;
            this.item.desc = this.item.desc
              ? this.item.desc.replace(/\n|\r\n/g, "<br>")
              : null;
          });
        }
      } catch (e) {}
    },
    buyChanges() {
      // this.dialogValue = '检测到有未支付的课程！是否去支付';
      // if (this.item.is_free) {
      //   this.freeCourse();
      // } else {
      let now_time = this.$moment(new Date()).unix();
      if (
        this.item.is_buy == 0 &&
        now_time < this.item.time_expire &&
        this.item.order_status == 0
      ) {
        this.dialogVisible = true;
      } else {
        this.payCourse();
      }
      // }
    },
    async freeCourse() {
      let params = {
        course_id: this.item.id,
        company_id: this.USER_INFO.company_id,
        user_id: this.USER_INFO.id,
      };
      try {
        let result = await this.$store.dispatch(
          "API_company/payFreeCourse",
          params
        );
        if (result.success) {
          this.$message.success("免费购买成功");
          this.getList();
        }
      } catch (e) {}
    },
    async payCourse() {
      let info = this.USER_INFO;
      let now_time = this.$moment(new Date()).unix();
      let params = {
        goods_type: 1,
        belongto: 3,
        goods_belongto: 3,
        source: this.PJSource,
        user_id: info.id,
        user_name: priorFormat(info.name_en, info.name_zh, this.LOCALE),
        company_id: info.company_id,
        goods_amount: 1,
        company_name: priorFormat(
          info.company_name_en,
          info.company_name_zh,
          this.LOCALE
        ),
        amount_total: this.item.company_is_vip
          ? this.item.price_actual * 100
          : this.item.price_scribe * 100, // 商品总价
        amount_paid: this.item.company_is_vip
          ? this.item.price_actual * 100
          : this.item.price_scribe * 100, //  实付金额
        payment: 1,
        // status: 0,
        related_id: this.item.id,
        goods_name: this.item.name,
        goods_price: this.item.price_scribe * 100,
      };
      if (
        this.item.order_id &&
        now_time < this.item.time_expire &&
        this.item.order_status == 0
      ) {
        // params.order_id = this.item.order_id;
        // params.order_no = this.item.order_no;
        this.payOrder(this.item.order_id);
      } else {
        try {
          let result = await this.$store.dispatch(
            "API_company/payCourse",
            params
          );
          if (result.success) {
            if (result.data == 1) {
              this.$message.success("购买成功");
              this.getList();
            } else {
              let obj = {
                info: this.item,
                order: result.data,
              };
              obj.info.payment = 1;
              this.$nextTick((e) => {
                this.$refs.payDialog.show(obj);
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async payOrder(id) {
      try {
        let result = await this.$store.dispatch("API_company/payCourseOrder", {
          order_id: id,
        });
        if (result.success) {
          if (result.data == 1) {
            this.$message.success("购买成功");
            this.getList();
          } else {
            let obj = {
              info: this.item,
              order: result.data,
            };
            obj.info.payment = 1;
            this.$nextTick((e) => {
              this.$refs.payDialog.show(obj);
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    paySuccess() {
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.detail {
  width: 1200px;
  margin: auto;
  padding-top: 16px;
  padding-bottom: 60px;
}
.topic {
  display: flex;
  align-items: center;
}
.openTitle {
  font-weight: bold;
}
</style>